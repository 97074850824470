import {TransparentChevrons} from "../PreOrder/PreOrder";
import React, {useEffect, useState} from "react";

import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import {ProdCard} from "../Catalog/ProdCard";
import {useSelector} from "react-redux";
import {getCurrencySymb, getItemChars} from "../../Hooks/GetFunctions";
import {useLanguage} from "../../Hooks/UseLang";

export const Recent = (props) => {
    const {title, itemsList} = props;

    const lang = useLanguage();
    const config = useSelector((state) => state);
    const [slider, setSlider] = useState();

    const products = useSelector((state) => state.products);
    const currentCurrency = useSelector((state) => state.currency);
    const neededProducts = Array.isArray(products) && Array.isArray(itemsList)
        ? products.filter(product => product && itemsList.includes(product.id))
        : [];

    const price = (product) => {
        switch (currentCurrency) {
            case "RUB":
                return product.price_rub;
            case "RUR":
                return product.price_rub;
            case "USD":
                return product.price_usd;
            case "UAH":
                return product.price_uah;
            default:
                return product.price;
        }
    };

    return (
        <section className="sResent section">
            <div className="container">
                <div className="section-title">
                    <h2>{title ? title : lang.Recent.titleRecent}</h2>
                </div>
                <Swiper
                    modules={[Pagination]}
                    breakpoints={{
                        0: {
                            spaceBetween: 16,
                        },
                        576: {
                            spaceBetween: 32,
                        },
                    }}
                    slidesPerView={"auto"}
                    className={"sResent__slider"}
                    onSwiper={setSlider}
                    pagination={{clickable: true}}>
                    {neededProducts.length > 0 &&
                        neededProducts.map((product, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <ProdCard
                                        product={product}
                                        name={product.name}
                                        isAvailable={product.is_available}
                                        price={`${config.currency === 'UAH' || config.currency === 'RUB'
                                            ? Math.ceil(price(product))
                                            : price(product).toFixed(2)
                                        } ${getCurrencySymb(config.currency)}`}
                                        itemId={product.id}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    <TransparentChevrons slider={slider}/>
                </Swiper>
            </div>
        </section>
    );
};
