import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useModifyValue(product, option) {
  const [modifyTxt, setModifyTxt] = useState([]);
  const config = useSelector((state) => state);
  console.log(product, "product");
  let RubsFor1USD =
    product?.prices?.initial?.RUB / product?.prices?.initial?.USD;
  let RubsFor1UAH =
    product?.prices?.initial?.RUB / product?.prices?.initial?.UAH;

  useEffect(() => {
    let newModifyTxt = [];
    for (let variant of option.variants) {
      if (variant.modify_value !== 0) {
        if (config.currency === "UAH") {
          newModifyTxt.push(`+${variant.modify_value / RubsFor1UAH} UAH`);
        } else if (config.currency === "USD") {
          newModifyTxt.push(`+${variant.modify_value / RubsFor1USD} USD`);
        } else {
          newModifyTxt.push(`+${variant.modify_value} RUB`);
        }
      } else {
        newModifyTxt.push("");
      }
    }

    setModifyTxt(newModifyTxt);
  }, [RubsFor1UAH, RubsFor1USD, config.currency, option.variants]);

  return modifyTxt;
}
