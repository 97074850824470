import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {MainPage} from "./blocks/MainPage/MainPage";
import "./sass/main.scss";
import {AboutPage} from "./blocks/AboutPage/AboutPage";
import {PolicyPage} from "./blocks/PolicyPage/PolicyPage";
import {CatalogPage} from "./blocks/CatalogPage/CatalogPage";
import {ProdCardPage} from "./blocks/ProdCardPage/ProdCardPage";
import {SearchPage} from "./blocks/SearchPage/SearchPage";
import {InfoPage} from "./blocks/InfoPage/InfoPage";
import {useEffect, useState} from "react";
import {getExchangeRates, getProductsByListId, getProductsFromGumRoad, getSearchResults} from "./Hooks/GetFunctions";
import {useDispatch, useSelector} from "react-redux";
import {setProducts} from "./index";
import PaymentFailedPage from "./blocks/AfterPaymentPages/PaymentFailedPage/PaymentFailedPage";
import PaymentSuccessPage from "./blocks/AfterPaymentPages/PaymentSuccessPage/PaymentSuccessPage";
import Loader from "./blocks/Loader/Loader";

function App() {
    //make hook later
    const config = useSelector((state) => state);
    const products = useSelector((state) => state.products);
    const listId = useSelector((state) => state.ids);
    const lang = useSelector((state) => state.lang);

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const productsJson = require('./products/products.json');
    const idMap = new Map(Object.entries(productsJson));

    useEffect(() => {
        if (lang === 'en-US') {
            dispatch({type: 'CHANGE_CURRENCY', payload: 'USD'})
        } else {
            dispatch({type: 'CHANGE_CURRENCY', payload: 'RUB'})
        }
    }, [lang])

    useEffect(() => {
        if (!window.searchResultId) {
            window.searchResultId = 0;
        }

        const thisSearchId = window.searchResultId + 1;
        window.searchResultId = thisSearchId;

        getSearchResults(config).then((data) => {
            if (thisSearchId === window.searchResultId) {
                dispatch({type: "CHANGE_SEARCH_RESULTS", payload: data});
            }
        });
    }, [config.searchTxt, config.lang, config.currency]);

    //currency
    useEffect(() => {
        window.localStorage.setItem(
            "BroGamesCurrency",
            JSON.stringify(config.currency)
        );
    }, [config.currency]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);

                const digiSellerProducts = await getProductsByListId(listId, lang);
                const gumroadProducts = await getProductsFromGumRoad();
                const rates = await getExchangeRates();

                const gumroadMap = new Map(gumroadProducts.map(product => [product.id, product]));

                const updatedProducts = digiSellerProducts.map((product) => {
                    const digiSellerId = product.id.toString();
                    const gumroadId = idMap.get(digiSellerId);
                    const gumroadProduct = gumroadMap.get(gumroadId);

                    if (gumroadProduct) {
                        const price_usd = gumroadProduct.price / 100;

                        return {
                            ...product,
                            name: gumroadProduct.name,
                            end_point: gumroadProduct.short_url.split('/').pop(),
                            price_usd: price_usd,
                            price_uah: price_usd * rates.uah,
                            price_rub: price_usd * rates.rub,
                        };
                    }

                    return product;
                });

                dispatch(setProducts(updatedProducts));
            } catch (error) {
                console.error("Error fetching products or exchange rates:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [listId, lang]);


    if (loading || products === []) {
        return (
            <Loader/>
        )
    }

    console.log("PRODUCTS: ", products);

    //end make hook later

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/:lang/" element={<MainPage/>}/>
                    <Route path="/:lang/about" element={<AboutPage/>}/>
                    <Route path="/:lang/policy" element={<PolicyPage/>}/>
                    {/**/}
                    <Route
                        path="/:lang/catalog"
                        element={<CatalogPage productType="all"/>}
                    />
                    {/*mb remake for /:category ...*/}
                    /*<Route
                    path="/:lang/catalog/accounts"
                    element={<CatalogPage productType="accounts"/>}
                />{/*
                    <Route
                        path="/:lang/catalog/keys"
                        element={<CatalogPage productType="keys"/>}
                    />
                    <Route
                        path="/:lang/catalog/top-up"
                        element={<CatalogPage productType={"topUp"}/>}
                    />
                    <Route
                        path="/:lang/catalog/currency"
                        element={<CatalogPage productType="currency"/>}
                    />
                    <Route
                        path="/:lang/catalog/accounts/:subcategory"
                        element={<CatalogPage productType="accounts"/>}
                    />
                    <Route
                        path="/:lang/catalog/keys/:subcategory"
                        element={<CatalogPage productType="keys"/>}
                    />
                    <Route
                        path="/:lang/catalog/top-up/:subcategory"
                        element={<CatalogPage productType={"topUp"}/>}
                    />
                    <Route
                        path="/:lang/catalog/currency/:subcategory"
                        element={<CatalogPage productType="currency"/>}
                    />*!/*/}
                    {/*working a little*/}
                    <Route path="/:lang/prod/:id" element={<ProdCardPage/>}/>
                    <Route path="/:lang/info/:id" element={<InfoPage/>}/>
                    {/*<Route path="/:lang/cart" element={<CartPage/>}/>
                    <Route path="/:lang/order-details" element={<OrderDetailsPage/>}/>*/}
                    <Route path="/:lang/search" element={<SearchPage/>}/>
                    <Route path="/payment/failed" element={<PaymentFailedPage/>}/>
                    <Route path="/payment/success" element={<PaymentSuccessPage/>}/>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
