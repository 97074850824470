import { EqualitySign, InfoCircle } from "../../SvgSpriptes";
import { useEffect } from "react";
import { getCurrencySymb, getSybolsAfterDot } from "../../Hooks/GetFunctions";

export const ProdCardUnit = (props) => {
  const {
    product,
    lang,
    payInpValue,
    setPayInpValue,
    getInpValue,
    setGetInpValue,
  } = props;

  useEffect(() => {
    setGetInpValue(
      (Number(payInpValue) / Number(product.units.price)).toFixed(2)
    );
  }, [payInpValue, product, setGetInpValue]);

  return (
    <div className="pcUnit">
      <div className="pcUnit__inp-box">
        <div className="pcUnit__i-row row align-items-center">
          <div className="col">
            <div className="pcUnit__i-title">
              Pay{" "}
              <strong>
                {getCurrencySymb(product.prices_unit.unit_currency)}
              </strong>
            </div>
            <input
              type="number"
              className="form-control pcUnit__i-input"
              value={payInpValue}
              onChange={(e) => {
                if (getSybolsAfterDot(e.target.value) > 2) {
                  setPayInpValue(Number(e.target.value).toFixed(2));
                } else {
                  setPayInpValue(Number(e.target.value));
                }

                setGetInpValue(
                  (
                    Number(e.target.value) / Number(product.units.price)
                  ).toFixed(2)
                );
                //Number(product.units.price) * Number(e.target.value)
              }}
            />
          </div>
          <div className="col-auto">
            <EqualitySign />
          </div>
          <div className="col">
            <div className="pcUnit__i-title">
              Get <strong>{product.prices_unit.unit_name}</strong>
            </div>
            <input
              type="number"
              className="form-control pcUnit__i-input"
              value={getInpValue}
              onChange={(e) => {
                if (getSybolsAfterDot(e.target.value) > 2) {
                  setGetInpValue(Number(e.target.value).toFixed(2));
                } else {
                  setGetInpValue(Number(e.target.value));
                }

                setPayInpValue(
                  (
                    Number(product.units.price) * Number(e.target.value)
                  ).toFixed(2)
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="pcUnit__s-box">
        <ProdCardUnitStrip
          txt={`${lang.minPurchase} ${product.prices_unit.unit_cnt_min} ${product.prices_unit.unit_name}`}
        />
        <ProdCardUnitStrip
          txt={`${lang.maxPurchase} ${product.prices_unit.unit_cnt_max} ${product.prices_unit.unit_name}`}
        />
      </div>
    </div>
  );
};

const ProdCardUnitStrip = (props) => {
  const { txt } = props;

  return (
    <div className="pcUnit__strip">
      <div className="pcUnit__s-row row align-items-center">
        <div className="col-auto">
          <InfoCircle />
        </div>
        <div className="pcUnit__s-txt col">{txt}</div>
      </div>
    </div>
  );
};
