import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { CatalogHeader } from "../CatalogHeader/CatalogHeader";
import { CatalogItems } from "../CatalogItems/CatalogItems";
import { Seo } from "../Seo/Seo";
import { useLanguage } from "../../Hooks/UseLang";
import { useTrackLang } from "../../Hooks/useTrackLang";

export const CatalogPage = (props) => {
  const { productType } = props;
  const lang = useLanguage().MarketPage;
  // console.log(productType);
  const trackLang = useTrackLang();

  return (
    <>
      <Seo
        title={`BroGamers | ${
          productType === "all"
            ? lang.market
            : productType === "topUp"
            ? lang.top
            : lang[`${productType}`]
        }`}
        description={lang.description}
        type="webapp"
        name="BroGamers"
        href={"/catalog"}
      />
      <h1 className="sr-only">{lang.h1Seo}</h1>
      <div className="main-wrapper main-wrapper--catalog">
        <Header />
        <main>
          <CatalogHeader />
          <CatalogItems productType={productType} />
        </main>
        <Footer />
      </div>
    </>
  );
};
