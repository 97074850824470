import info from "../../infoPagesContent.json";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const InfoBlock = (props) => {
  const config = useSelector((state) => state);

  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (info[params.id]) {
      setData(info[params.id][config.lang]);
    }
  }, [config.lang, params.id]);

  if (data) {
    return (
      <section className="section sInfo">
        <div className="container">
          {data.beforeHTML && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.beforeHTML }}></div>
          )}
          <div className="section-title text-xl-center">
            {!data.beforeHTML && <h1>{data.mainTitle}</h1>}
            {data.beforeHTML && <h2>{data.mainTitle}</h2>}
          </div>
          <ul className="sInfo__list">
            {data.list.map((item, index) => {
              return (
                <li key={index} className="sInfo__list-item">
                  <div className="sInfo__txt">
                    <div
                      className="sInfo__title"
                      dangerouslySetInnerHTML={{ __html: item.title }}></div>
                    <div
                      className="sInfo__descr"
                      dangerouslySetInnerHTML={{ __html: item.descr }}></div>
                  </div>
                  {item.imgs &&
                    item.imgs.length > 0 &&
                    item.imgs.map((src, index) => {
                      return (
                        <div className="sInfo__img">
                          <img
                            loading="lazy"
                            src={src}
                            alt="info img"
                            width={"auto"}
                            height={"auto"}
                          />
                        </div>
                      );
                    })}
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    );
  } else {
    return (
      <section className="section">
        <div className="container">
          <h2>404</h2>
        </div>
      </section>
    );
  }
};
