import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useLanguage } from "../../Hooks/UseLang";
import {
  getCurrencySymb,
  getProdTypeOption, getSelectClasses, getServerToLink,
  getSortOrderOption,
  getSubcategoryNameById,
} from "../../Hooks/GetFunctions";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { ProdCard } from "../Catalog/ProdCard";
import { ChevronLeftPagin, ChevronRightPagin } from "../../SvgSpriptes";
import { useTrackSubcategories } from "../../Hooks/useTrackSubcategories";

function setLocation(curLoc) {
  try {
    window.history.pushState(null, null, curLoc);
    return;
  } catch (e) {
    console.log(e);
  }
  window.location.hash = "#" + curLoc;
}

export const CatalogItems = (props) => {
  const { productType } = props;
  let urlParams = new URLSearchParams(window.location.search);

  const config = useSelector((state) => state);
  const langAll = useLanguage();
  const lang = langAll.CatalogItems;
  const trackSubcategoriesDone = useTrackSubcategories(productType);

  const [totalPages, setTotalPages] = useState(-1);
  const [currentPage, setCurrentPage] = useState(urlParams.get("page") || 1);
  const [alertTxt, setAlertTxt] = useState(lang.loadingTxt);

  const products = useSelector((state) => state.products);
  const currentCurrency = useSelector((state) => state.currency);

  const price = (product) => {
    switch (currentCurrency) {
      case "RUB":
        return product.price_rub;
      case "RUR":
        return product.price_rub;
      case "USD":
        return product.price_usd;
      case "UAH":
        return product.price_uah;
      default:
        return product.price;
    }
  };

  const sortedProducts = [...products].sort((a, b) => {
    switch (config.sortOrder) {
      case "name":
        return a.name.localeCompare(b.name);
      case "nameDESC":
        return b.name.localeCompare(a.name);
      case "price":
        return price(a) - price(b);
      case "priceDESC":
        return price(b) - price(a);
      default:
        return 0; // якщо немає сортування
    }
  });

  return (
      <section className="sItems section">
        <div className="container">
          <div className="sItems__top-row row align-items-center">
            <div className="col-md">
              <div className="section-title">
                <h2>
                  {config.prodType === "all" ? (
                      lang.title
                  ) : (
                      <>
                        {!config.currentPlatform && <>{lang.all} </>}
                        <span className="text-capitalize">{config.prodType}</span>{" "}
                        {config.currentPlatform ? (
                            <>
                              {lang.for}{" "}
                              {getSubcategoryNameById(
                                  config.currentPlatform,
                                  config
                              )}
                            </>
                        ) : (
                            ""
                        )}
                      </>
                  )}
                </h2>
              </div>
            </div>
            <SortOrderSelect />
          </div>

          {sortedProducts.length > 0 ? (
              <>
                <div className="sItems__row row">
                  {sortedProducts.map((product, index) => (
                      <div
                          key={index}
                          className="sItems__col col-sm-6 col-md-4 col-xl-3"
                      >
                        <ProdCard
                            product={product}
                            itemId={product.id}
                            name={product.name}
                            isAvailable={product.is_available}
                            price={`${config.currency === 'UAH' || config.currency === 'RUB'
                                ? Math.ceil(price(product))
                                : price(product).toFixed(2)
                            } ${getCurrencySymb(config.currency)}`}
                        />
                      </div>
                  ))}
                </div>
              </>
          ) : (
              <div className="h4">{alertTxt}</div>
          )}
        </div>
      </section>
  );
};

export const SortOrderSelect = (props) => {
  const lang = useLanguage().CatalogItems;
  const config = useSelector((state) => state);
  const dispatch = useDispatch();

  let orderOptions = [
    { value: "name", label: lang.sortName },
    { value: "nameDESC", label: lang.sortNameDesc },
    { value: "price", label: lang.sortPrice },
    { value: "priceDESC", label: lang.sortPriceDesc },
  ];

  return (
      <CatalogItemsSelect
          value={getSortOrderOption(orderOptions, config.sortOrder)}
          options={orderOptions}
          placeholder={lang.sortOrderPlaceholder}
          onChange={(e) => {
            dispatch({ type: "CHANGE_SORTORDER", payload: e.value });
          }}
      />
  );
};

export const ProdTypeSelect = (props) => {
  const { onChangeHandler } = props;
  const navigate = useNavigate();
  const lang = useLanguage().CatalogItems;
  const config = useSelector((state) => state);

  let productTypeOptions = [
    { value: "/catalog", label: lang.all, reduxKey: "all" },
    { value: "/catalog/accounts", label: lang.accounts, reduxKey: "accounts" },
    { value: "/catalog/keys", label: lang.keys, reduxKey: "keys" },
    { value: "/catalog/top-up", label: lang.topUp, reduxKey: "topUp" },
    { value: "/catalog/currency", label: lang.currency, reduxKey: "currency" },
  ];

  return (
      <CatalogItemsSelect
          value={getProdTypeOption(productTypeOptions, config.prodType)}
          options={productTypeOptions}
          onChange={(e) => {
            onChangeHandler
                ? onChangeHandler(e)
                : navigate(`/${getServerToLink(config.lang)}${e.value}`);
          }}
      />
  );
};

const CatalogItemsSelect = (props) => {
  const { value, options, onChange, placeholder } = props;

  return (
      <div className="col-6 col-md-auto">
        <Select
            placeholder={placeholder}
            classNames={{
              ...getSelectClasses(),
              option: (state) =>
                  `custom-select__menu-option ${state.isSelected ? "active" : ""}`,
            }}
            onChange={(e) => onChange(e)}
            value={value}
            isSearchable={false}
            options={options}
        />
      </div>
  );
};
