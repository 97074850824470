import React, { useEffect } from "react";
import { getPrimalOptions } from "../../Hooks/GetFunctions";
import { useModifyValue } from "../../Hooks/useModifyValue";

export const ProdCardOptions = (props) => {
  const { product, stateOptions, setStateOptions, alertOptions } = props;
  console.log(product);
  //create options
  useEffect(() => {
    setStateOptions(getPrimalOptions(product));
  }, [product, setStateOptions]);

  //changes data in redux, and adjust price

  if (product.options) {
    return (
      <div className="sProd__options">
        {product.options &&
          product.options.length > 0 &&
          product.options.map((item, index) => {
            return (
              <div
                className={`sProd__o-box sProd__o-box--${item.type}`}
                key={index}>
                {item.label && (
                  <div
                    className={`sProd__o-title sProd__o-title--${item.type}`}>
                    {item.label}:
                    {item.required ? (
                      <span className="required-symb">*</span>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {item.type === "radio" && (
                  <ProdCardOptionsRadio
                    product={product}
                    option={item}
                    stateOptions={stateOptions}
                    setStateOptions={setStateOptions}
                  />
                )}
                {(item.type === "text" || item.type === "textarea") && (
                  <ProdCardOptionsText
                    product={product}
                    option={item}
                    stateOptions={stateOptions}
                    setStateOptions={setStateOptions}
                  />
                )}
                {item.type === "select" && (
                  <ProdCardOptionsSelect
                    product={product}
                    option={item}
                    stateOptions={stateOptions}
                    setStateOptions={setStateOptions}
                  />
                )}
                {item.type === "checkbox" && (
                  <ProdCardOptionsCheckbox
                    product={product}
                    option={item}
                    stateOptions={stateOptions}
                    setStateOptions={setStateOptions}
                  />
                )}
                <ProdCardOptionsAlert
                  alertOptions={alertOptions}
                  thisOptionId={item.id}
                />
              </div>
            );
          })}
      </div>
    );
  }
};

export const ProdCardOptionsAlert = (props) => {
  const { alertOptions, thisOptionId } = props;

  if (alertOptions.hasOwnProperty(thisOptionId)) {
    return (
      <div className="sProd__alert">{alertOptions[thisOptionId].text}</div>
    );
  }
};

export const ProdCardOptionsText = (props) => {
  const { option, stateOptions, setStateOptions } = props;

  if (option.type === "text") {
    return (
      <input
        type="text"
        required={option.required}
        value={stateOptions[option.id]}
        className="form-control sProd__input sProd__input--text"
        onChange={(e) => {
          let result = {
            ...stateOptions,
          };

          result[option.id] = e.target.value;
          setStateOptions(result);
        }}
      />
    );
  }
  if (option.type === "textarea") {
    return (
      <textarea
        value={stateOptions[option.id]}
        required={option.required}
        className="form-control sProd__input sProd__input--textarea"
        onChange={(e) => {
          let result = {
            ...stateOptions,
          };

          result[option.id] = e.target.value;
          setStateOptions(result);
        }}
      />
    );
  }
};

export const ProdCardOptionsSelect = (props) => {
  const { option, product, stateOptions, setStateOptions } = props;
  const modifyTxt = useModifyValue(product, option);

  return (
    <select
      className={`form-select sProd__input sProd__input--select ${
        stateOptions[option.id] &&
        (stateOptions[option.id].selected ? "" : "placeholder")
      }`}
      required={option.required}
      value={stateOptions[option.id] && stateOptions[option.id].selected}
      onChange={(e) => {
        let result = {
          ...stateOptions,
        };

        result[option.id].selected = e.target.value;
        setStateOptions(result);
      }}>
      <option value={""} disabled={true} className="d-none">
        {option.label}
      </option>
      {option.variants.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.text} {modifyTxt[index]}
          </option>
        );
      })}
    </select>
  );
};

export const ProdCardOptionsCheckbox = (props) => {
  const { option, product, stateOptions, setStateOptions } = props;
  const modifyTxt = useModifyValue(product, option);

  return (
    <div className="sProd__o-items sProd__o-items--checkboxes">
      {option.variants.map((variant, index) => {
        return (
          <div className="sProd__o-item sProd__o-item--checkbox" key={index}>
            <label className="custom-checkbox">
              <input
                type="checkbox"
                className="invisible"
                name={option.name}
                value={variant.value}
                checked={
                  stateOptions[option.id] &&
                  stateOptions[option.id][variant.value].checked
                }
                onChange={(e) => {
                  let result = {
                    ...stateOptions,
                  };

                  for (let key in result[option.id]) {
                    if (key === e.target.value) {
                      result[option.id][key].checked =
                        !result[option.id][key].checked;
                    }
                  }

                  setStateOptions(result);
                }}
              />
              <span className="c-square"></span>
              <span className="c-txt">
                {variant.text}{" "}
                <span className="modify">{modifyTxt[index]}</span>
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const ProdCardOptionsRadio = (props) => {
  const { product, option, stateOptions, setStateOptions } = props;
  const modifyTxt = useModifyValue(product, option);

  return (
    <div className="sProd__o-items">
      {option.variants.map((variant, index) => {
        // console.log(variant.modify_value);
        console.log(product);
        return (
          <div className="sProd__o-item" key={index}>
            <label className="custom-checkbox">
              <input
                type="radio"
                className="invisible"
                name={option.name}
                required={option.required}
                value={variant.value}
                checked={
                  stateOptions[option.id] &&
                  stateOptions[option.id][variant.value].checked
                }
                onChange={(e) => {
                  let result = {
                    ...stateOptions,
                  };

                  for (let key in result[option.id]) {
                    result[option.id][key].checked =
                      key === e.target.value ? true : false;
                  }

                  setStateOptions(result);
                }}
              />
              <span className="c-square c-square--round"></span>
              <span className="c-txt">
                {variant.text}{" "}
                <span className="modify">{modifyTxt[index]}</span>
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
};
