import PreOrderBg from "../../img/PreOrder.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import React, {useEffect, useState} from "react";
import {ChevronLeft, ChevronRight} from "../../SvgSpriptes";
import {useLanguage} from "../../Hooks/UseLang";
import {ProdCard} from "../Catalog/ProdCard";
import {getCurrencySymb, getProducts, getProductsByListId} from "../../Hooks/GetFunctions";
import {useDispatch, useSelector} from "react-redux";


export const PreOrder = (props) => {

    const config = useSelector((state) => state);
    const currentCurrency = useSelector((state) => state.currency)
    const products = useSelector((state) => state.products)

    const preOrderIds = [3828572, 3828573, 3828574];
    const productsPreOrder = products.filter((product) => preOrderIds.includes(product.id));


    const price = (product) => {
        switch (currentCurrency) {
            case "RUB":
                return product.price_rub;
            case "RUR":
                return product.price_rub;
            case "USD":
                return product.price_usd;
            case "UAH":
                return product.price_uah;
            default:
                return product.price;
        }
    };

    /*useEffect(() => {
        /!* getProducts(config, 1, config.digIds.preOrder, 100).then((data) => {
             if (data && data.product) {
                 setProducts([...data.product]);
             }
         });*!/
        getProductsByListId(ids, "en-US").then(data => dispatch(setProducts(data)))
    }, [config.lang, config.currency, config]);

    console.log("PRODUCTS: ", productsA);*/

    //
    const [slider, setSlider] = useState();
    const lang = useLanguage().PreOrder;

    if (products.length > 0) {
        return (
            <section className="sPreOrder section">
                <div className="container">
                    <div className="sPreOrder__box">
                        <div className="sPreOrder__bg">
                            <img
                                loading="lazy"
                                src={PreOrderBg}
                                alt="order background"
                                width={"auto"}
                                height={"auto"}
                            />
                        </div>
                        <div className="section-title">
                            <h2>{lang.title}</h2>
                        </div>
                        <Swiper
                            modules={[Pagination]}
                            breakpoints={{
                                0: {
                                    spaceBetween: 16,
                                },
                                576: {
                                    spaceBetween: 32,
                                },
                            }}
                            slidesPerView={"auto"}
                            className={"sPreOrder__slider"}
                            onSwiper={setSlider}
                            pagination={false}>
                            {productsPreOrder.map((product, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <ProdCard
                                            product={product}
                                            itemId={product.id}
                                            name={product.name}
                                            isAvailable={product.is_available}
                                            price={`${config.currency === 'UAH' || config.currency === 'RUB'
                                                ? Math.ceil(price(product))
                                                : price(product).toFixed(2)
                                            } ${getCurrencySymb(config.currency)}`}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                            {/*<TransparentChevrons slider={slider}/>*/}
                        </Swiper>
                    </div>
                </div>
            </section>
        );
    }
};

export const TransparentChevrons = (props) => {
    let {slider, displayClasses} = props;

    return (
        <>
            <div
                className={`swiper-tp-btn ${
                    displayClasses ? displayClasses : "d-none d-md-flex"
                } prev`}
                onClick={() => {
                    slider.slidePrev();
                }}>
                <ChevronLeft/>
            </div>
            <div
                className={`swiper-tp-btn ${
                    displayClasses ? displayClasses : "d-none d-md-flex"
                } next`}
                onClick={() => {
                    slider.slideNext();
                }}>
                <ChevronRight/>
            </div>
        </>
    );
};
