import {PacmanLoader} from "react-spinners";
import styles from "./Loader.module.scss";

const Loader = () => {
    return (
        <div className={styles.loader__wrapper}>
            <PacmanLoader size={75} color="#F0691D"/>
        </div>
    )
}

export default Loader;