import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Search } from "./Search";
import { Seo } from "../Seo/Seo";
import { useLanguage } from "../../Hooks/UseLang";
import { useTrackLang } from "../../Hooks/useTrackLang";

export const SearchPage = (props) => {
  const trackLang = useTrackLang();
  const lang = useLanguage().SearchPage;
  return (
    <>
      <Seo
        title={`BroGamers | ${lang.title}`}
        description={lang.description}
        type="webapp"
        name="BroGamers"
        href={"/search"}
      />
      <h1 className="sr-only">{lang.description}</h1>
      <div className="main-wrapper main-wrapper--catalog">
        <Header hideSearch={true} />
        <main>
          <Search />
        </main>
        <Footer />
      </div>
    </>
  );
};
