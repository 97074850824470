import styles from "../AfterPaymentPages.module.scss";
import {NavLink} from "react-router-dom";
import failedIcon from "../../../img/failed.png";
/*import { useLanguage } from "../../../Hooks/UseLang";*/
import enLang from  "../../../langs/en-US.json"

const PaymentFailedPage = () => {
    /*const lang = useLanguage().PaymentFailed;*/
    return (
        <main className={styles.main__payment}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1>{enLang["PaymentFailed"].h1}</h1>
                    <img src={failedIcon} alt="Failed"/>
                </div>
                <h2>{enLang["PaymentFailed"].h2}</h2>
                <NavLink to="/">
                    <div className={styles.button}>{enLang["PaymentFailed"].button}</div>
                </NavLink>
            </div>
        </main>
    );
};

export default PaymentFailedPage;
