import React from "react";

import { Helmet } from "react-helmet-async";

import avatarImg from "../../img/avatar.jpg";

const Seo = ({ title, description, type, name, href, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={href} />
      <meta property="og:image" content={image || avatarImg} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={href} />
    </Helmet>
  );
};

export { Seo };
