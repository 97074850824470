import {Link} from "react-router-dom";
import {CartIcon} from "../../SvgSpriptes";
import {useSelector} from "react-redux";
import {useGetContent} from "../../Hooks/useGetContent";
import {useCartActiveClasses} from "../../Hooks/useCartActiveClasses";
import {useLanguage} from "../../Hooks/UseLang";
import {getServerToLink} from "../../Hooks/GetFunctions";

export const ProdCard = (props) => {
    const {name, price, addClasses, itemId, isAvailable, product} = props;

    const lang = useLanguage();
    const config = useSelector((state) => state);
    const content = useGetContent(itemId);
    const btnClasses = useCartActiveClasses(itemId);

    return (
        <Link
            className={`prodCard ${addClasses ? addClasses : ""} ${
                isAvailable === 0 ? "muted" : ""
            }`}
            to={`/${getServerToLink(config.lang)}/prod/${itemId}`}
            target="_blank">
            <div className="prodCard__img-box">
                <img
                    loading="lazy"
                    src={
                        content.imgGallery
                            ? content.imgGallery[0]
                            : `https://bro-gamers.com/api/get_digiseller_image?id_d=${itemId}&w=248&h=248&crop=true`
                    }
                    alt="product"
                    width={"auto"}
                    height={"auto"}
                />
                <div className="prodCard__tags">
                    <ul className="prodCard__tags-row row">
                        {content.tags.map((tag, index) => {
                            return (
                                <li className="col-auto" key={index}>
                                    <TagBox txt={tag.txt.trim()} color={tag.color}/>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className="prodCard__name">{content.name ? content.name : name}</div>

            <div className="prodCard__price-row row">
                <div className="col">
                    <div className="prodCard__price">
                        {price}
                    </div>
                </div>
                <div className="col-auto">
                    <div
                        className={`prodCard__cart-btn ${btnClasses}`}
                        onClick={() => {
                        }}>
                        <CartIcon/>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export const TagBox = (props) => {
    let {txt, color} = props;

    return (
        <div className="tag" style={{background: color ? color : "#6c757d"}}>
            {txt}
        </div>
    );
};
