import whatWeDoImg1 from "../../img/sWhat-1.jpg";
import whatWeDoImg2 from "../../img/sWhat-2.jpg";
import whatWeDoImg3 from "../../img/sWhat-3.jpg";
import { useLanguage } from "../../Hooks/UseLang";

const whatWeDoImages = [
  {
    img: whatWeDoImg1,
  },
  {
    img: whatWeDoImg2,
  },
  {
    img: whatWeDoImg3,
  },
];

export const WhatWeDo = (props) => {
  const lang = useLanguage().WhatWeDo;

  return (
    <>
      <div className="section sWhat">
        <div className="container">
          <div className="section-title d-lg-none">
            <h2>{lang.title}</h2>
            <p>{lang.descr}</p>
          </div>
          <div className="sWhat__row row align-items-center">
            <ul className="sWhat__col sWhat__col--left col-lg-5 col-xl-6">
              {whatWeDoImages.map((item) => (
                <li className="sWhat__img" key={item.img}>
                  <img
                    src={item.img}
                    loading="lazy"
                    alt="what we do"
                    width={"auto"}
                    height={"auto"}
                  />
                </li>
              ))}
            </ul>
            <div className="sWhat__col sWhat__col--right col-lg-7 col-xl-6">
              <div className="sWhat__content">
                <div className="section-title d-none d-lg-block">
                  <h3>{lang.title}</h3>
                  <p>{lang.descr}</p>
                </div>
                <h4>{lang.subTitle}</h4>
                <ol>
                  {lang.list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
