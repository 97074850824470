import { useLanguage } from "../../Hooks/UseLang";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencySymb, getSearchResults } from "../../Hooks/GetFunctions";
import { ProdCard } from "../Catalog/ProdCard";
import { Zoom } from "../../SvgSpriptes";

export const Search = (props) => {
  const lang = useLanguage().SearchPage;
  const config = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <section className="sItems section">
      <div className="container">
        <div className="sItems__top-row row align-items-center">
          <div className="col-md">
            <div className="section-title">
              <h2 className="mb-3">{lang.title}</h2>
              <div className="sItems__input-wrap">
                <input
                  type="text"
                  value={config.searchTxt}
                  className="sItems__input form-control"
                  onChange={(e) => {
                    dispatch({
                      type: "CHANGE_SEARCHTXT",
                      payload: e.target.value,
                    });
                  }}
                />
                <div
                  className="sItems__search-btn"
                  onClick={() => {
                    getSearchResults(config).then((data) => {
                      dispatch({
                        type: "CHANGE_SEARCH_RESULTS",
                        payload: data,
                      });
                    });
                  }}>
                  <Zoom color={"white"} />
                </div>
              </div>
              <p>
                {lang.totalResults}{" "}
                {config.searchResults && config.searchResults.length}
              </p>
            </div>
          </div>
        </div>
        {config.searchResults && config.searchResults.length > 0 && (
          <div className="sItems__row row">
            {config.searchResults.map((product, index) => {
              return (
                <div
                  key={index}
                  className="sItems__col col-sm-6 col-md-4 col-xl-3">
                  <ProdCard
                    product={product}
                    isAvailable={product.is_avalaible || "unknown"}
                    itemId={product.id}
                    name={product.name}
                    price={`${Math.ceil(product.price)} ${getCurrencySymb(
                      config.currency
                    )}`}
                  />
                </div>
              );
            })}
          </div>
        )}
        {config.searchResults && config.searchResults.length === 0 && (
          <div className="sItems__nothing-found">{lang.nothingFound}</div>
        )}
      </div>
    </section>
  );
};
