import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { InfoBlock } from "../InfoBlock/InfoBlock";
import { Seo } from "../Seo/Seo";
import { useLanguage } from "../../Hooks/UseLang";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTrackLang } from "../../Hooks/useTrackLang";

export const InfoPage = (props) => {
  const lang = useLanguage().GameInfo;
  const params = useParams();
  const trackLang = useTrackLang();

  return (
    <>
      <Seo
        title={`BroGamers | ${lang.title}`}
        description={lang.mainDescription}
        type="webapp"
        name="BroGamers"
        href={`/info/${params.id}`}
      />
      <h1 className="sr-only">{lang.mainDescription}</h1>
      <div className="main-wrapper main-wrapper--catalog">
        <Header />
        <main>
          <InfoBlock />
        </main>
        <Footer />
      </div>
    </>
  );
};
