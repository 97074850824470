import styles from "../AfterPaymentPages.module.scss";
import success from "../../../img/success.png";
import {NavLink} from "react-router-dom";
/*import {useLanguage} from "../../../Hooks/UseLang";*/

import enLang from "../../../langs/en-US.json";

const PaymentSuccessPage = () => {
    /*const lang = useLanguage().PaymentSuccess;*/
    return (
        <main className={styles.main__payment}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1>{enLang["PaymentSuccess"].h1}</h1>
                    <img src={success} alt="Success"/>
                </div>
                <h2>{enLang["PaymentSuccess"].h2}</h2>
                <NavLink to="/">
                    <div className={styles.button}>{enLang["PaymentSuccess"].button}</div>
                </NavLink>
            </div>
        </main>
    );
};

export default PaymentSuccessPage;
