import { Seo } from "../Seo/Seo";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { useLanguage } from "../../Hooks/UseLang";
import { useTrackLang } from "../../Hooks/useTrackLang";

export const PolicyPage = (props) => {
  const lang = useLanguage().PolicyPage;
  const trackLang = useTrackLang();
  return (
    <>
      <Seo
        title={`BroGamers | ${lang.policy}`}
        description={lang.mainDescription}
        type="webapp"
        name="BroGamers"
        href={"/policy"}
      />
      <div className="main-wrapper">
        <Header />
        <main>
          <section className="sPolicy section">
            <div className="sPolicy__container container">
              <h1 className="sPolicy__title">{lang.mainTitle}</h1>
              <div className="sPolicy__box">
                <h2 className="sPolicy__title">{lang.pointOne.title}</h2>
                <div>
                  {lang.pointOne.list.map((item) => (
                    <p
                      key={item.item}
                      dangerouslySetInnerHTML={{ __html: item.item }}></p>
                  ))}
                </div>
                <h3 className="sPolicy__title">{lang.pointTwo.title}</h3>
                <div>
                  {lang.pointTwo.list.map((item) => (
                    <p key={item.item}>{item.item}</p>
                  ))}
                </div>
                <h3 className="sPolicy__title">{lang.pointThree.title}</h3>
                <div>
                  {lang.pointThree.listOne.map((item) => (
                    <div key={item.item}>
                      <h4 className="sPolicy__title">{item.subTitle}</h4>
                      <p>{item.item}</p>
                    </div>
                  ))}
                  {lang.pointThree.listTwo.map((item) => (
                    <div key={item.item}>
                      <h4 className="sPolicy__title">{item.subTitle}</h4>
                      <p>{item.item}</p>
                    </div>
                  ))}
                  {lang.pointThree.listThree.map((item) => (
                    <div key={item.item}>
                      <h4 className="sPolicy__title">{item.subTitle}</h4>
                      <p>{item.item}</p>
                    </div>
                  ))}
                  {lang.pointThree.listFour.map((item) => (
                    <div key={item.item}>
                      <h4 className="sPolicy__title">{item.subTitle}</h4>
                      <p>{item.item}</p>
                    </div>
                  ))}
                </div>
                <h3 className="sPolicy__title">{lang.pointFour.title}</h3>
                {lang.pointFour.listOne.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.pointFour.listTwo.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.pointFour.listThree.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                <h3 className="sPolicy__title">{lang.pointFive.title}</h3>

                {lang.pointFive.listOne.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                <h2 className="sPolicy__title">{lang.pointSix.title}</h2>
                {lang.pointSix.listOne.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                <h3 className="sPolicy__title">{lang.pointSeven.title}</h3>
                {lang.pointSeven.listOne.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
              </div>
              <h2>{lang.TermsOfReturn.title}</h2>
              <div className="sPolicy__box">
                {lang.TermsOfReturn.listOne.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item.item }}></p>
                  </div>
                ))}
                {lang.TermsOfReturn.listTwo.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.TermsOfReturn.listThree.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.TermsOfReturn.listFour.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.TermsOfReturn.listFive.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
              </div>
              <h2 className="sPolicy__title">{lang.PrivacyPolicy.title}</h2>
              <div className="sPolicy__box">
                <p
                  dangerouslySetInnerHTML={{
                    __html: lang.PrivacyPolicy.subTitle,
                  }}></p>
                {lang.PrivacyPolicy.listOne.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                <h4 className="sPolicy__title">
                  {lang.PrivacyPolicy.secondTitle}
                </h4>
                {lang.PrivacyPolicy.listTwo.map((item) => (
                  <div key={item.item}>
                    <h5 className="sPolicy__title">{item.subTitle}</h5>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.PrivacyPolicy.listThree.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.PrivacyPolicy.listFour.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
                {lang.PrivacyPolicy.listFive.map((item) => (
                  <div key={item.item}>
                    <h4 className="sPolicy__title">{item.subTitle}</h4>
                    <p>{item.item}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};
